import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faYoutube, faTelegram, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPrint } from '@fortawesome/free-solid-svg-icons'; // Correct import
import './App.css'; 
import profilePicture from "./black_avatar.png";

const App = () => {
  return (
    <div className="container">
      <div className="card">
        <div className="header">
          <div className="profile-picture">
            <img src={profilePicture} alt="Profile" />
          </div>
          <div className="name-bio">
            <h2>Nikolay Gavris</h2>
            <p>Programmer, Engineer, 3D maker, Photographer</p>
          </div>
        </div>
        <div className="buttons">
          <a href="https://www.instagram.com/n1koster" target="_blank" rel="noopener noreferrer">
            <button className="button">
              <FontAwesomeIcon icon={faInstagram} /> Instagram
            </button>
          </a>
          <a href="https://youtube.com/@nikoster" target="_blank" rel="noopener noreferrer">
            <button className="button">
              <FontAwesomeIcon icon={faYoutube} /> YouTube
            </button>
          </a>
          <a href="https://t.me/n1koster" target="_blank" rel="noopener noreferrer">
            <button className="button">
              <FontAwesomeIcon icon={faTelegram} /> Telegram
            </button>
          </a>
          <a href="mailto:nikgavris@gmail.com">
            <button className="button">
              <FontAwesomeIcon icon={faEnvelope} /> Email
            </button>
          </a>
          <a href="https://github.com/NIKOSTERs" target="_blank" rel="noopener noreferrer">
            <button className="button">
              <FontAwesomeIcon icon={faGithub} /> Github
            </button>
          </a>
          <a href="https://www.printables.com/@NIKOSTER_427915" target="_blank" rel="noopener noreferrer">
            <button className="button">
              <FontAwesomeIcon icon={faPrint} /> Printables
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default App;